
import { defineComponent, onMounted, PropType, ref, watch } from '@vue/runtime-core'
import JSONEditor, { JSONEditorMode, SelectionPosition } from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.min.css'
export default defineComponent({
  props: {
    modes: {
      type: Array as PropType<Array<JSONEditorMode>>,
      default: ['code', 'view'],
    },
    autoSetMode: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'update:isError'],
  setup($props, context) {
    /* init editor */
    let editor: JSONEditor | undefined = undefined
    let containerRef = ref<InstanceType<typeof HTMLElement>>()

    function setText(content: string) {
      try {
        if (!(JSON.parse(content) instanceof Object)) return
        if (editor) {
          let select:
            | {
                start: SelectionPosition
                end: SelectionPosition
                text: string
              }
            | undefined = undefined
          if (editor.getTextSelection) select = editor.getTextSelection()
          editor.setText(content)
          if (editor.setTextSelection && select) editor.setTextSelection(select.start, select.end)
        }
      } catch (error) {
        console.log(error)
      }
    }
    onMounted(() => {
      if (containerRef.value) {
        editor = new JSONEditor(containerRef.value, {
          modes: $props.modes,
          enableTransform: false,
          enableSort: false,
          navigationBar: false,
          onChangeText(jsonString) {
            context.emit('update:modelValue', jsonString)
          },
          onValidationError(errors) {
            context.emit('update:isError', errors.length !== 0)
          },
        })
        setText($props.modelValue)
      }
    })

    watch(
      () => $props.modelValue,
      value => {
        if (editor) {
          setText(value)
        }
      }
    )

    return {
      containerRef,
    }
  },
})
