<template>
  <div class="page-generic">
    <div class="request-panel">
      <div class="header">
        <div class="options">
          <div class="menu" @click="importParams">导入</div>
          <div
            class="menu"
            ref="exportParamsRef"
            @click="exportParams"
            :data-clipboard-text="exportText"
          >导出</div>
          <div class="menu" @click="clear">清空</div>
          <div class="menu" @click="wiki">文档</div>
        </div>
      </div>
      <div class="form">
        <!-- <el-radio-group v-model="env">
          <el-radio-button label="k8sdev">dev</el-radio-button>
          <el-radio-button label="test"></el-radio-button>
          <el-radio-button label="prod"></el-radio-button>
        </el-radio-group> -->
        <el-input
          v-model="interfaceName"
          clearable
          placeholder="例：top.itning.api.UserApi"
          style="margin-top: 16px"
        >
          <template #prepend>interface</template>
        </el-input>
        <el-input v-model="method" clearable placeholder="例：findUser" style="margin-top: 16px">
          <template #prepend>调用方法</template>
        </el-input>
        <div
          style="display: flex; justify-content: space-between; margin-top: 20px; align-items: center"
        >
          <h3>参数：</h3>
          <el-button
            type="primary"
            style="width: 100px; height: 30px; position: relative; top: 6px"
            @click="submit"
          >提交</el-button>
        </div>
        <JsonEditor
          v-model="params"
          v-model:isError="__jsonError"
          style="margin-top: 16px; height: 50vh"
        ></JsonEditor>
      </div>
    </div>
    <div class="result-panel">
      <JsonEditor :modes="['view']" v-model="result" style="height: 100vh"></JsonEditor>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, reactive, ref, toRaw, toRefs } from '@vue/runtime-core'
import JsonEditor from '@/components/JsonEditor.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import _ from 'lodash'
import http from '@/utils/http'
import Clipboard from 'clipboard'

export default defineComponent({
  components: {
    JsonEditor,
  },
  setup() {
    /*
    init paramsModel
    */
    let paramsModel = reactive({
      method: '',
      interfaceName: '',
      env: 'k8sdev',
      params: '[]',
      __jsonError: false,
    })

    // initial paramsModel of data
    function initParamsModel(data: string, cb?: Function) {
      try {
        let obj = (JSON.parse(data || '[]') as unknown) as typeof paramsModel
        Object.assign(paramsModel, obj)
        paramsModel.params = obj.params
        if (cb) cb()
      } catch (error) {
        ElMessage.error('导入的内容格式不正确')
      }
    }

    /* init params from localStorage */
    onBeforeMount(() => {
      let catchModel = localStorage.getItem('paramsModel') || '[]'
      initParamsModel(catchModel)
    })

    /* submit */
    let result = ref('')
    function submit() {
      if (!paramsModel.method || !paramsModel.interfaceName || !paramsModel.env || paramsModel.__jsonError) {
        ElMessage({
          type: 'warning',
          message: '请完整填写表单',
          duration: 1000,
        })
        return
      }

      let requestParam = _.cloneDeep(toRaw(paramsModel))
      try {
        let paramObj = JSON.parse(requestParam.params)

        if (!(paramObj instanceof Array)) {
          ElMessage.error('参数必须为空数组或对象数组')
          return
        }
        Object.assign(requestParam, {
          params: paramObj,
        })
        result.value = '{}'

        localStorage.setItem('paramsModel', JSON.stringify(paramsModel))

        http('POST', `${window.location.origin}/dubbo/invoke`, requestParam).then(resp => {
          result.value = JSON.stringify(resp)
          console.log(resp)
        })
      } catch (error) {
        console.log(error)
      }
    }

    /* export Params */
    let exportParamsRef = ref()
    let exportText = ref('')
    let clipborad: ClipboardJS

    onMounted(() => {
      clipborad = new Clipboard(exportParamsRef.value)
    })

    function exportParams() {
      let exportObj = _.cloneDeep(_.omit(paramsModel, '__jsonError'))
      exportText.value = JSON.stringify(exportObj)
      clipborad.on('success', () => {
        exportText.value = ''
        ElMessage.success('导出成功，内容已在剪切板')
      })
      clipborad.on('error', () => {
        ElMessage.error('复制失败')
      })
    }

    /* import Params */
    function importParams() {
      ElMessageBox.prompt('', '请输入内容', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'textarea',
      }).then(({ value }) => {
        initParamsModel(value, () => {
          ElMessage.success('导入成功')
        })
      })
    }

    /* clear */
    function clear() {
      paramsModel.params = '[]'
      paramsModel.method = ''
      paramsModel.interfaceName = ''
      paramsModel.env = 'k8sdev'
    }

    function wiki() {
      window.open('http://wiki.learnableai.cn/pages/viewpage.action?pageId=1881978')
    }

    return {
      ...toRefs(paramsModel),
      submit,
      wiki,
      result,
      exportParamsRef,
      exportParams,
      exportText,
      importParams,
      clear,
    }
  },
})
</script>
<style lang="scss" scoped>
.page-generic {
  width: 100vw;
  min-height: 100vh;
  display: flex;

  h3 {
    font-weight: 400;
    color: #1f2f3d;
    font-size: 22px;
    margin: 35px 0 20px;
  }
  .request-panel {
    flex: 3;
    .header {
      width: 100%;
      height: 40px;
      background: rgb(51, 50, 50);
      .options {
        display: flex;
        height: 100%;
        .menu {
          color: rgb(206, 206, 206);
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgb(88, 88, 88);
          &:hover {
            cursor: pointer;
            color: white;
            background: lighten($color: rgb(51, 50, 50), $amount: 20);
          }
        }
      }
    }

    .form {
      padding: 20px;
    }
  }
  .result-panel {
    background: rgb(233, 233, 233);
    flex: 2;
  }
}
</style>
<style lang="scss">
.page-generic {
  .el-input-group__prepend {
    width: 60px;
  }
}
</style>
