
import { defineComponent, onBeforeMount, onMounted, reactive, ref, toRaw, toRefs } from '@vue/runtime-core'
import JsonEditor from '@/components/JsonEditor.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import _ from 'lodash'
import http from '@/utils/http'
import Clipboard from 'clipboard'

export default defineComponent({
  components: {
    JsonEditor,
  },
  setup() {
    /*
    init paramsModel
    */
    let paramsModel = reactive({
      method: '',
      interfaceName: '',
      env: 'k8sdev',
      params: '[]',
      __jsonError: false,
    })

    // initial paramsModel of data
    function initParamsModel(data: string, cb?: Function) {
      try {
        let obj = (JSON.parse(data || '[]') as unknown) as typeof paramsModel
        Object.assign(paramsModel, obj)
        paramsModel.params = obj.params
        if (cb) cb()
      } catch (error) {
        ElMessage.error('导入的内容格式不正确')
      }
    }

    /* init params from localStorage */
    onBeforeMount(() => {
      let catchModel = localStorage.getItem('paramsModel') || '[]'
      initParamsModel(catchModel)
    })

    /* submit */
    let result = ref('')
    function submit() {
      if (!paramsModel.method || !paramsModel.interfaceName || !paramsModel.env || paramsModel.__jsonError) {
        ElMessage({
          type: 'warning',
          message: '请完整填写表单',
          duration: 1000,
        })
        return
      }

      let requestParam = _.cloneDeep(toRaw(paramsModel))
      try {
        let paramObj = JSON.parse(requestParam.params)

        if (!(paramObj instanceof Array)) {
          ElMessage.error('参数必须为空数组或对象数组')
          return
        }
        Object.assign(requestParam, {
          params: paramObj,
        })
        result.value = '{}'

        localStorage.setItem('paramsModel', JSON.stringify(paramsModel))

        http('POST', `${window.location.origin}/dubbo/invoke`, requestParam).then(resp => {
          result.value = JSON.stringify(resp)
          console.log(resp)
        })
      } catch (error) {
        console.log(error)
      }
    }

    /* export Params */
    let exportParamsRef = ref()
    let exportText = ref('')
    let clipborad: ClipboardJS

    onMounted(() => {
      clipborad = new Clipboard(exportParamsRef.value)
    })

    function exportParams() {
      let exportObj = _.cloneDeep(_.omit(paramsModel, '__jsonError'))
      exportText.value = JSON.stringify(exportObj)
      clipborad.on('success', () => {
        exportText.value = ''
        ElMessage.success('导出成功，内容已在剪切板')
      })
      clipborad.on('error', () => {
        ElMessage.error('复制失败')
      })
    }

    /* import Params */
    function importParams() {
      ElMessageBox.prompt('', '请输入内容', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'textarea',
      }).then(({ value }) => {
        initParamsModel(value, () => {
          ElMessage.success('导入成功')
        })
      })
    }

    /* clear */
    function clear() {
      paramsModel.params = '[]'
      paramsModel.method = ''
      paramsModel.interfaceName = ''
      paramsModel.env = 'k8sdev'
    }

    function wiki() {
      window.open('http://wiki.learnableai.cn/pages/viewpage.action?pageId=1881978')
    }

    return {
      ...toRefs(paramsModel),
      submit,
      wiki,
      result,
      exportParamsRef,
      exportParams,
      exportText,
      importParams,
      clear,
    }
  },
})
